<template>
  <b-modal
    :id="id"
    :title="title"
    centered
    size="xl"
    no-close-on-backdrop
    hideFooter
    @hidden="back"
  >
    <my-tabs
      :listTabs="listTabs"
      defaultActive="user-edit-tab-account"
      typeCss="active-full"
      @setActive="setActive"
    >
    </my-tabs>
    <div class="page-container addBusiness-fix ">
      <validation-observer ref="tabAccount">
        <info-equip
          ref="info"
          :dataInput="dataInput"
          class="tab-pane"
          :class="{'active':activeItem==='info'}"
        />
        <file-require
          class="tab-pane"
          :idDetail="idDetail"
          :class="{'active':activeItem==='fileRequire'}"
        />
        <list-equip
          class="tab-pane"
          :idDetail="idDetail"
          :class="{'active':activeItem==='listEquip'}"
        />

      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'
import InfoEquip from './tab-equip/Info.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '@/views/occupational-safety-health/declare-equip/constants/ConstantsApi'
import ListEquip from './tab-equip/ListEquipment.vue'
import FileRequire from './tab-equip/FileRequire.vue'

export default {
  components: {
    BButton,
    MyTabs,
    InfoEquip,
    ListEquip,
    ValidationProvider,
    ValidationObserver,
    BModal,
    FileRequire,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetailInjury: {
      type: Object,
      default: _ => {},
    },
    idDetail: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeItem: 'info',
      detailData: {},
      listTabs: [
        {
          key: 'info',
          title: 'Thông tin',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'fileRequire',
          title: 'Hồ sơ cần nộp',
          icon: 'FilePlusIcon',
          isDisabled: false,
        },
        {
          key: 'listEquip',
          title: 'Danh sách thiết bị',
          icon: 'ListIcon',
          isDisabled: false,
        },
      ],
      dataInput: {
        Code: '',
        UserDeclare: '',
        DateDeclare: '',
        CertificateNumber: '',
        InspectionOrganization: '',
        fileName: null,
        modelFormData: null,

      },
    }
  },

  watch: {
    detailData(val) {
      if (val) {
        this.dataInput = {
          Code: this.detailData.code,
          UserDeclare: this.detailData.userDeclare,
          DateDeclare: this.detailData.dateDeclare,
          CertificateNumber: this.detailData.certificateNumber,
          InspectionOrganization: this.detailData.inspectionOrganization,
          fileName: this.detailData.fileName,
        }
      }
    },
    idDetail(val) {
      if (val) {
        this.fetchDetailData(this.idDetail)
      }
    },
  },
  methods: {
    setActive(tabActive) {
      this.activeItem = tabActive
    },
    back() {
      this.dataInput = {
        Code: '',
        UserDeclare: '',
        DateDeclare: '',
        CertificateNumber: '',
        InspectionOrganization: '',
        fileName: null,
        modelFormData: null,
      }
      this.activeItem = 'info'
    },
    async fetchDetailData(id) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_DECLARE}${id}`)
      this.detailData = data
    },

  },
}
</script>
