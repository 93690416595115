<template>
  <div>
    <!--phần header-->
    <button-all-header
      :arrayExcel="[]"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      :hideAdd="false"
      :hideDelete="false"
      :showBtnMultiDelete="showBtnMultiDelete"
      @importFile="() => {}"
      @clickDowloadSample="() => {}"
      @clickExportExcel="() => {}"
      @clickDelete="() => {}"
      @clickAdd="() => {}"
      @search="search($event)"
    />
    <!--Phần Bảng -->
    <vue-good-table
      ref="user-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'code'">
          <b-badge variant="light-primary">
            {{ props.row.code }}
          </b-badge>
        </span>
      </template>
    </vue-good-table>
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ShowFile from '@/components/show-file/ShowFile.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

// import EditEquip from './components/EditEquip.vue'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    ShowFile,
    // EditEquip,
  },
  props: {
    idDetail: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      totalRecord: 10,
      confirmModalId: 'confirm-modal',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      isActive: false,
      modalIdCreate: 'modalIdCreate',
      dataList: [],

      columns: [
        {
          label: 'MÃ HIỆU THIẾT BỊ',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN THIẾT BỊ',
          field: 'name',
          sortable: false,
        },
        {
          label: 'NƠI LẮP ĐẶT',
          field: 'location',
          sortable: false,
        },
        {
          label: 'SỐ LƯỢNG',
          field: 'quantity',
          sortable: false,
        },
      ],
      currentPage: 0,
      urlQuery: {
        declareEquipmentId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {

    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      this.urlQuery.declareEquipmentId = this.idDetail
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_EQUIPMENT_DECLARE, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },
    search(val) {
      if (val != null) {
        this.urlQuery.declareEquipmentId = this.idDetail
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.declareEquipmentId = this.idDetail
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },
  },
}
</script>

<style lang="scss">
#inspectorId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }
}
</style>
