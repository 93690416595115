<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="code"
        >
          <label for="code">Mã khai báo</label>
          <b-form-input
            v-model="dataInput.Code"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="description"
        >
          <label for="description">Ngày cấp </label>

          <date-time-picker
            v-model="dataInput.DateDeclare"
            :disabledInput="true"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="inspector"
        >
          <label for="inspector">Người khai báo </label>
          <b-form-input
            v-model="dataInput.UserDeclare"
            placeholder="Người khai báo"
            :disabled="true"
          /></b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="company"
        >
          <label for="company">Số giấy chứng nhận kiểm định</label>
          <b-form-input
            v-model="dataInput.CertificateNumber"
            placeholder="Số giấy chứng nhận kiểm định"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Tổ chức kiểm định"
          label-for="date"
        >
          <b-form-input
            v-model="dataInput.InspectionOrganization"
            placeholder="Tổ chức kiểm định"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="File đính kèm"
          label-for="date"
        >
          <div class="d-flex">
            <b-form-input
              v-model="fileName"
              :disabled="true"
            />

            <input
              ref="upload-file"
              type="file"
              class="d-none"
            />

          </div>
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    DateTimePicker,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dataInput: {
      type: Object,
      default: _ => {},

    },
  },
  data() {
    return {
      required,
      code: {
        required: 'Mã số là bắt buộc',
      },
      UserDeclare: {
        required: 'Tên người khai báo là bắt buộc',
      },
      DateDeclare: {
        required: 'Ngày cấp là bắt buộc',
      },
      fileName: 'Không có file đính kèm',
    }
  },
  watch: {
    dataInput(val) {
      if (this.dataInput.fileName) {
        this.fileName = this.dataInput.fileName
      }
    },
  },

}
</script>
