<template>
  <div>
    <!--Phần Bảng -->
    <vue-good-table
      ref="user-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'stt'">
          {{ props.row.stt }}
        </span>
        <!-- Chức năng -->
        <span v-else-if="props.column.field == 'action'">
          <div
            class="d-flex justify-content-between item-file-download"
          >
            <span
              v-if="props.row.fileName"
              v-b-tooltip.hover.top="'Tải tệp'"
              class="ml-1"
              @click="dowloadFile(props.row.fileName, props.row.typeFile)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Tải tệp'"
                icon="DownloadIcon"
                size="18"
                class="text-body"
              />
            </span>
          </div>

        </span>
      </template>
    </vue-good-table>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown, BButton,
  BDropdownItem,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ShowFile from '@/components/show-file/ShowFile.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import store from '@/views/management-statistical/store'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ConfirmModal,
    BBadge,
    BDropdown,
    ShowFile,
    BButton,
    BDropdownItem,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    idDetail: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      totalRecord: 10,
      isActive: false,
      modalIdCreate: 'modalIdCreate',
      dataList: [
        {
          stt: 1,
          name: 'Bản sao phô tô Giấy chứng nhận kết quả kiểm định của thiết bị.',
          fileName: null,
          typeFile: 'File1',
        },
        {
          stt: 2,
          name: 'Phiếu khai báo sử dụng đối tượng kiểm định (Ban hành kèm theo mẫu tại Phụ lục Iđ, Nghị định số 44/2016/NĐ-CP ngày 15/5/2016)',
          fileName: null,
          typeFile: 'File2',
        },
      ],
      isShow: false,
      columns: [
        {
          label: 'STT',
          field: 'stt',
          sortable: false,
          width: '40px',
        },
        {
          label: 'TÊN HỒ SƠ',
          field: 'name',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          sortable: false,
        },
      ],
      currentPage: 0,
      urlQuery: {
        declareEquipmentId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      dataInput: {
        fileName: null,
        modelFormData: {},
      },
      isNotification: false,
      typeFile: '',
    }
  },
  created() {
    this.getData(this.idDetail)
  },
  methods: {
    // Lấy danh sách fileName
    async getData(id) {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_FILE_REQUIRE, {
        params: { id },
      })
      const FileName1 = data[0]
      const FileName2 = data[1]
      if (FileName1) {
        this.dataList[0].fileName = FileName1
      }
      if (FileName2) {
        this.dataList[1].fileName = FileName2
      }
    },

    // Tải file
    async dowloadFile(fileName, fileType) {
      const name = fileName
      const params = {
        id: this.idDetail,
        fileType,
      }
      await store.downloadExportFile(name, ConstantsApi.DOWLOAD_FILE_REQUIRE, params)
    },

  },
}
</script>

<style lang="scss">
#inspectorId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }
}
</style>
